import axios from "axios";
export default {
    get(url, query = {}) {
        return new Promise((resolve, reject)=>{
            axios.get(url, {
                params: query
            }).then(res=>{
                resolve(res.data)
            })
        }).catch(err=>{
            reject(err)
        })
    },
    post(url, body = {}) {
        return new Promise((resolve, reject)=>{
            axios.post(url, body).then(res=>{
                resolve(res.data)
            })
        }).catch(err=>{
            reject(err)
        })
    },
    put(url, body = {}) {
        return axios.put(url, body)
    },
    delete(url) {
        return axios.delete(url)
    }
}