import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import store from './store';
import "u-reset.css";
import api from "./api/index";
import url from "./api/url";
Vue.use(ElementUI);
Vue.prototype.$api = api;
// build时解开注释
// const basrUrl = 'https://www.jntsetyyg.com:7777'
// for (const key in url) {
//   url[key] = basrUrl + url[key]
// }
Vue.prototype.$url = url

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
