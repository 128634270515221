import Vue from 'vue'
import VueRouter from 'vue-router';
import store from '../store'
import api from "../api/index";
import url from "../api/url"

Vue.use(VueRouter)

const routes = [
  //登录页
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    redirect: '/index'
  },
  //首页
  {
    path: '/index',
    name: 'Index',
    meta: {
      title: "系统管理"
    },
    component: () => import('../views/Index.vue'),
    children: [
      // {
      //   path: '/business',
      //   name: 'Business',
      //   meta: {
      //     title: "业务管理"
      //   },
      //   component: () => import('../views/Business.vue'),
      //   children: [
      // // Order 小程序管理
      // {
      //   path: 'order',
      //   name: 'Order',
      //   meta: {
      //     title: "小程序管理"
      //   },
      //   component: () => import('../views/Angel_order.vue'),
      // },
      // // 官网管理
      // // {
      // //   path: 'website',
      // //   name: 'Website',
      // //   meta: {
      // //     title: "官网管理"
      // //   },
      // //   component: () => import('../views/Angel_website.vue')
      // // },
      // //survey小程序自测
      // {
      //   path: 'survey',
      //   name: 'Survey',
      //   meta: {
      //     title: "天使自测"
      //   },
      //   component: () => import('../views/Angel_survey.vue')
      // },
      // // 挂号中心管理
      // {
      //   path: 'register',
      //   name: 'Register', 
      //   meta: {
      //     title: "挂号中心管理"
      //   },
      //   component: () => import('../views/registerView.vue')
      // },
      // // 挂号平台管理
      // {
      //   path: 'platform',
      //   name: 'Platform', 
      //   meta: {
      //     title: "挂号平台管理"
      //   },
      //   component: () => import('../views/platformView.vue')
      // },
      // //Role 身份管理
      // {
      //   path: 'role',
      //   name: 'Role',
      //   meta: {
      //     title: "身份管理"
      //   },
      //   component: () => import('../views/RoleView.vue')
      // },
      // //User 用户管理
      // {
      //   path: 'user',
      //   name: 'User', meta: {
      //     title: "用户管理"
      //   },
      //   component: () => import('../views/User.vue')
      // },
      // // 菜单管理
      // {
      //   path: 'menu',
      //   name: 'Menu', 
      //   meta: {
      //     title: "栏目管理"
      //   },
      //   component: () => import('../views/MenuView.vue')
      // },
      // // 密码管理
      // {
      //   path: 'password',
      //   name: 'Password', 
      //   meta: {
      //     title: "密码管理"
      //   },
      //   component: () => import('../views/Password.vue')
      // },
      //   ]
      // },
    ]
  },
  {
    path: '*',
    redirect: '/index'
  }
]

const router = new VueRouter({
  // mode: "hash",
  // base: process.env.BASE_URL,
  routes
});
// router.addRoute("Business", { path: "order",component: () => import("../views/Angel_order.vue") }),
// router.addRoute("Business", { path: "survey", component: () => import("../views/Angel_survey.vue") })
router.beforeEach(async (to, from, next) => {
  if (localStorage.getItem("username")) {
    if (!store.state.route.length) {
      if (sessionStorage.role) {
        await store.dispatch("fetchMenu", sessionStorage.role);
      } else {
        await api.post(url.getInfo, { username: localStorage.username }).then(async (res) => {
          if (res.code === 200) {
            sessionStorage.setItem("role", res.role);
            sessionStorage.setItem("username", res.username);
            await store.dispatch("fetchMenu", res.role);
          } else {
            localStorage.removeItem("username")
            next("/login")
          }
        }).catch(() => {
          localStorage.removeItem("username")
          next("/login")
        })
      }
    }
    if (to.path === '/login') next('/index')
    else next()
  } else {
    if (to.path !== '/login') next("/login")
    else next()
  }
})

export default router


