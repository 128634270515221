
// const baseurl = "http://39.106.83.60:7001"
// const baseurl = "https://39.106.83.60:7001"
// const baseurl = "https://www.jntsetyyg.com"
const baseurl = "https://www.jntsetyyg.com"
export default {
    // 登录
    login: baseurl + "/api/login",
    // 获取信息
    getInfo: baseurl + "/api/getInfo",
    // 更新密码接口
    password: baseurl + "/api/password",
    // 退出登录
    logout: baseurl + "/api/logout",
    // 挂号中心管理
    order: baseurl + "/api/order",
    // 天使儿童管理
    angel: baseurl + "/api/angel",
    // 预约挂号管理
    reservation: baseurl + "/api/reservation",
    // 挂号平台管理
    platform: baseurl + "/api/platform",
    // 官方挂号管理
    official: baseurl + "/api/official",
    // 在线挂号管理
    online: baseurl + "/api/online",
    // 官方挂号中心管理
    planning: baseurl + "/api/planning",
    // 官方预约中心管理
    plan: baseurl + "/api/plan",
    // 预约中心管理
    plans: baseurl + "/api/plans",
    // 总挂号数据管理
    whole: baseurl + "/api/whole",
    // pc端官网管理
    website: baseurl + "/api/website",
    // pc端心理官网管理
    xlgwgh: baseurl + "/api/xlgwgh",
    // pc自测
    survey: baseurl + "/api/survey",
    // 小程序自测
    evaluation: baseurl + "/api/wxcp",
    // 小程序残联补助接口
    remnant: baseurl + "/api/wxcl",
    // 小程序企划小程序测评
    assess: baseurl + "/api/wxgfyyzx",
    // 天使心理测评中心
    psychology: baseurl + "/api/psychology",
    // 用户管理
    user: baseurl + "/api/user",
    // 身份管理
    role: baseurl + "/api/role",
    // 权限管理
    menu: baseurl + "/api/menu",
    // 身份权限
    rolemenu: baseurl + "/api/rolemenu",
}