import Vue from 'vue'
import Vuex from 'vuex'
import $api from "../api/index"
import $url from "../api/url"
import router from "../router"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    route: []
  },
  getters: {
  },
  mutations: {
    addRoute(state, payload) {
      // console.log(payload);
      // 首字母大写
      let character = payload.name.charAt(0).toUpperCase();
      // console.log(character);
      let name = character + payload.name.slice(1);
      // 动态路由的添加
      router.addRoute("Index", {
        path: payload.name,
        component: () => import(`@/views/${name}.vue`),
        meta: {
          add: payload.add,
          edit: payload.edit,
          delete: payload.delete
        }
      });
      // route数组进行动态添加,需要在main组件当中自动生成导航信息
      state.route.push({
        label: payload.label,
        parent_label: payload.parent_label,
        name: payload.name
      });
    }
  },
  // 获取菜单信息
  actions: {
    async fetchMenu(context, payload) {
      // console.log(payload);
      // 发起请求(先引入api)
      if (payload == 1) {
        const res = await $api.get($url.menu);
        // console.log(res);
        res.data.forEach(v => {
          context.commit("addRoute", {
            name: v.name, 
            label: v.label,
            parent_label: v.parent_label,
            add: v.add,
            edit: v.edit,
            delete: v.delete
          });
        });
      } else {
        const res = await $api.get($url.role + "/" + payload);
        console.log(res);
        res.data.menu.forEach(v => {
          if (v.auth != 2) {
            // 传入的方法
            context.commit("addRoute", {
              name: v.menu.name,
              label: v.menu.label,
              parent_label: v.menu.parent_label,
              add: v.add,
              edit: v.edit,
              delete: v.delete
            })
          }
        })
      }
      // const res = await $api.get($url.role + "/" + payload);
      // console.log(res);
      // res.data.menu.forEach(v => {
      //   if (v.auth != 2) {
      //     // 传入的方法
      //     context.commit("addRoute", {
      //       name: v.menu.name,
      //       label: v.menu.label,
      //       parent_label: v.menu.parent_label,
      //       // add: v.add,
      //       // edit: v.edit,
      //       // delete: v.delete
      //     })
      //   }
      // })
    }
  },
  modules: {
  }
})
